<template>
  <div class="row" v-if="formData.endereco">
    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
      v-model="formData.endereco.intCep"
      name="intCep"
      label="CEP"
      v-mask="'99.999-999'"
      required
      @blur="buscarEndereco"
    />

    <ed-input-text
      class="'col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"
      v-model="formData.endereco.strLogradouro"
      name="strLogradouro"
      label="Endereço"
      :rules="formData.endereco.intCep ? 'required' : ''"
    />

    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
      v-model="formData.endereco.strNumero"
      name="strNumero"
      label="Número"
    />

    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
      v-model="formData.endereco.strComplemento"
      name="strComplemento"
      label="Complemento"
    />

    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
      v-model="formData.endereco.strBairro"
      name="strBairro"
      label="Bairro"
      :rules="formData.endereco.intCep ? 'required' : ''"
    />

    <ed-input-select
      class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
      v-model="formData.endereco.strEstado"
      name="strEstado"
      label="Estado"
      itemText="strUf"
      itemValue="strUf"
      :items="arrayEstados"
      :rules="formData.endereco.intCep ? 'required' : ''"
    />

    <ed-input-auto-complete
      class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"
      v-model="formData.endereco.strCidade"
      label="Cidade"
      name="strCidade"
      route="System/Municipio"
      item-value="strNome"
      :filters="{
        strEstado: this.formData.endereco.strEstado,
        strBusca: formData.endereco.strCidade,
      }"
      :disabled="!this.formData.endereco.strEstado"
      :rules="formData.endereco.intCep ? 'required' : ''"
      :initializeItems="formData.endereco.strCidade ? true : false"
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputTime,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  inject: [
    //'formData'
  ],
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdInputText,
    EdInputSelect,
    EdInputTipo,
    EdInputTextArea,
    EdInputTime,
    EdInputSwitch,
    EdInputAutoComplete,
  },
  mounted() {
    this.boolCarregado = true;
    this.getEstado();
  },
  created() {},
  data() {
    return {
      boolCarregado: false,
      arrayEstados: [],
    };
  },
  watch: {
    intRiscoId() {
      this.getRisco();
    },
    formData() {},
  },
  methods: {
    getEstado() {
      this.arrayEstados = [];

      this.$root.$request
        .get("System/Estado", { intRegistroPagina: 50 })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            this.arrayEstados = objResponse.result.data;
          }
        });
    },

    buscarEndereco() {
      // if (this.formData.endereco.intId){
      //   return;
      // }

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("System/Cep", { intCep: this.formData.endereco.intCep })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result?.intId) {
            this.formData.endereco.strLogradouro = objResponse.result.strEndereco;
            this.formData.endereco.strBairro = objResponse.result.strBairro;
            this.formData.endereco.strEstado = objResponse.result.strEstado;
            this.formData.endereco.strCidade = objResponse.result.strCidade;
          }
        });
    },
  },
};
</script>
