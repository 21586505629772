<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Cadastro Geral</span>

        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intFuncionarioId"
            label="Funcionário"
            name="intFuncionarioId"
            route="Cadastro/Funcionario"
            :itemList="formData.funcionario ? [formData.funcionario] : []"
            :filters="{
              boolMostrarEmpresas: true,
            }"
            @selected="setFuncionario"
            rules="required"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intCadastroGeralEmpresaId"
            label="Empresa"
            name="intCadastroGeralEmpresaId"
            route="Cadastro/CadastroGeral"
            :itemList="getEmpresaFuncionario()"
            :disabled="!formData.intFuncionarioId"
            rules="required"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intTipoAbsenteismoId"
            name="intTipoAbsenteismoId"
            label="Tipo de Absenteísmo"
            :items="bind.arrayTipoAbsenteismo"
            rules="required"
            :disabled="!formData.intFuncionarioId || !formData.intCadastroGeralEmpresaId"
          />

          <ed-input-date
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.strDataIncidente"
            name="strDataIncidente"
            label="Data do Incidente"
            required
            rules="required"
            :disabled="!boolDisableForm"
          />

          <ed-input-time
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.strHoraIncidente"
            name="strHoraIncidente"
            label="Hora do Incidente"
            required
            rules="required"
            :disabled="!boolDisableForm"
          />
        </div>

        <span class="form-title">ATESTADO MÉDICO</span>

        <div class="row">
          <ed-input-date
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.strDataAtestado"
            name="strDataAtestado"
            label="Data do Atendimento"
            required
            rules="required"
            :disabled="!boolDisableForm"
          />

          <ed-input-time
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.strHoraAtestado"
            name="strHoraAtestado"
            label="Hora do Atendimento"
            required
            rules="required"
            :disabled="!boolDisableForm"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.boolInternacao"
            name="boolInternacao"
            label="Houve Internação"
            :items="bind.arrayBoolean"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
            v-model="formData.intDuracaoTratamento"
            name="intDuracaoTratamento"
            label="Duração Estimada do Tratamento"
            type="number"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intCidId"
            label="CID"
            name="intCidId"
            route="Cadastro/Cid"
            rules="required"
            item-text="strDescricao"
            :itemList="formData.cid ? [formData.cid] : []"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intProfissionalId"
            label="Médico Que Emitiu o Atestado"
            name="intProfissionalId"
            route="Cadastro/Profissional"
            rules="required"
            :filters="{
              intTipoProfissionalId: 269,strBusca:formData.intProfissionalId ? formData.intProfissionalId : null
            }"
            :itemList="formData.profissional ? [formData.profissional] : []"
            :initializeItems="formData.intProfissionalId ? true : false"
          />

          <ed-input-auto-complete
            v-if="!boolExibirCat"
            class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.strCodigoLesao"
            label="Código da descrição da lesão"
            name="strCodigoLesao"
            item-text="strCodigo"
            item-value="strCodigo"
            route="Esocial/EsocialTabelaConteudo"
            :filters="{
              strBusca: this.formData.strCodigoLesao,
              intEsocialTabelaId: 17,
            }"
            rules="required"
            :initializeItems="formData.strCodigoLesao ? true : false"
          />

          <ed-input-text-area
            v-if="!boolExibirCat"
            class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.strDescricaoLesao"
            label="Descrição complementar da Lesão"
            name="strDescricaoLesao"
          />

          <ed-input-text-area
            v-if="boolExibirCat"
            class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.strDiagnostico"
            label="Diagnóstico Provável"
            name="strDiagnostico"
          />

          <ed-input-text-area
            v-if="boolExibirCat"
            class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.strObservacao"
            label="Observações"
            name="strObservacao"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left"  v-if="formData.intTipoAbsenteismoId == 1348
          ">
        <cadastro-cat
          :formData="formData"
          :key="'cadastro-cat-' + $root.$session.versao"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputSelect,
  EdInputAutoComplete,
  EdInputDate,
  EdInputTime,
  EdInputText,
  EdInputTextArea,
} from "@/components/common/form";

import CadastroCat from "./cat.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputSelect,
    EdInputAutoComplete,
    EdInputDate,
    EdInputTime,
    EdInputText,
    EdInputTextArea,
    CadastroCat,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  inject: ["bind"],
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    boolExibirCat() {
      return !(
        this.formData.intCadastroGeralEmpresaId &&
        this.formData.intFuncionarioId &&
        this.formData.intTipoAbsenteismoId == 1348
      );
    },
    boolDisableForm() {
      return (
        this.formData.intCadastroGeralEmpresaId &&
        this.formData.intFuncionarioId &&
        this.formData.intTipoAbsenteismoId
      );
    },
  },
  methods: {
    setFuncionario(obj) {
      if (obj) {
        this.formData.funcionario = obj;

        this.formData.intCadastroGeralUnidadeId = obj.intCadastroGeralUnidadeId;
        this.formData.intCadastroGeralSetorId = obj.intCadastroGeralSetorId;
        this.formData.intFuncaoId = obj.intFuncaoId;
      }
    },

    getEmpresaFuncionario() {
      let arrayEmpresa = [];
      if (this.formData.funcionario) {
        console.log('this.formData.funcionario',this.formData.funcionario)
        this.formData.funcionario.vinculos.forEach((element) => {
          arrayEmpresa.push(element.empresa);
        });
      }

      return arrayEmpresa;
    },
  },
};
</script>
