<template>
  <div class="row">
    <div
      class="
        col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
        text-left
        pt-0
        mt-0
      "
    >
      <span class="form-title">Informações da CAT</span>

      <div class="row">
        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.intTipoCatId"
          name="intTipoCatId"
          label="Tipo da CAT"
          :items="bind.arrayTipoCat"
          rules="required"
          :info="'Inicial – que refere-se a primeira comunicação do acidente ou doença do trabalho;\n\nRabertura – quando houver reinício de tratamento ou afastamento por agravamento da lesão (comunicado anteriormente ao INSS);\n\nComunicado de óbito – se refere a comunicação ocorrido após a emissão da CAT inicial. IMPORTANTE: deverá ser anexada a cópia da Certidão de Óbito e laudo de necropsia, quando houver.'"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.intTipoAcidenteId"
          name="intTipoAcidenteId"
          label="Tipo de Acidente"
          :items="bind.arrayTipoAcidenteCat"
          rules="required"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.intTipoIniciaTivaId"
          name="intTipoIniciaTivaId"
          label="Iniciativa"
          :items="bind.arrayTipoIniciativaCat"
          rules="required"
        />

        <ed-input-time
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.strHoraTrabalhada"
          name="strHoraTrabalhada"
          label="Horas Trabalhadas Antes do Acidente"
          required
          rules="required"
          info="Informar o número de horas decorridas desde o  início da jornada de trabalho até o momento do acidente. No caso de doença, o campo deverá ficar em branco."
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.strCodigoSituacaoGeradora"
          label="Código da situação geradora do acidente"
          name="strCodigoSituacaoGeradora"
          item-text="strNome"
          item-value="strCodigo"
          route="Esocial/EsocialTabelaConteudo"
          :filters="{
            strBusca: this.formData.strCodigoSituacaoGeradora,
            intEsocialTabelaId: 15,
          }"
          :initializeItems="formData.strCodigoSituacaoGeradora ? true : false"
          :info="'Descrever a situação ou a atividade de trabalho desenvolvida pelo acidentado e por outros diretamente relacionados ao acidente.\n\nTratando-se de acidente de trajeto, especificar o deslocamento e informar se opercurso foi ou não alterado ou interrompido por motivos alheios ao trabalho.\n\nNo caso de doença, descrever a atividade de trabalho, o ambiente ou as condiçõesem que o trabalho era realizado.'"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.boolObito"
          name="boolObito"
          label="Houve Óbito"
          :items="bind.arrayBoolean"
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.strDataObito"
          name="strDataObito"
          label="Data do Óbito"
          :rules="this.formData.boolObito ? 'required' : ''"
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.boolPolicial"
          name="boolPolicial"
          label="Houve comunicação à autoridade policial?"
          :items="bind.arrayBoolean"
          info="Informar se houve ou não registro policial. No caso de constar 1 (SIM), deverá ser encaminhada cópia do documento ao INSS oportunamente"
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.strDataUltimoDiaTrabalhado"
          name="strDataUltimoDiaTrabalhado"
          label="Último dia Trabalhado"
          rules="required"
          info=" informar a data do último dia em que efetivamente houve trabalho do acidentado, ainda que a jornada não tenha sido completa."
        />
      </div>

      <span class="form-title">Local do Acidente</span>

      <div class="row">
        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.intTipoLocalAcidenteId"
          name="intTipoLocalAcidenteId"
          label="Tipo do Local do Acidente"
          :items="bind.arrayTipoLocalAcidenteCat"
          rules="required"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.strDescricaoLocalAcidente"
          name="strDescricaoLocalAcidente"
          label="Especificação do local do acidente"
          rules="required"
          info="Informar de maneira clara e precisa o local onde ocorreu o acidente (Exemplo: pátio, rampa de acesso, posto de trabalho, nome da rua, etc.)."
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.intTipoInscricaoLocalAcidenteId"
          name="intTipoInscricaoLocalAcidenteId"
          label="Tipo de inscrição do local"
          :items="bind.arrayTipoInscricaoLocalAcidenteCat"
          rules="required"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0"
          v-model="formData.strDocumentoLocalAcidente"
          name="strDocumentoLocalAcidente"
          label="Documento do local do acidente"
          rules="required"
          type="number"
        />
        <ed-endereco class="pa-0 ma-0" :formData="formData" />
      </div>

      <span class="form-title">PARTE ATINGIDA PELO ACIDENTE</span>

      <div class="row">
        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.strCodigoParteAtingida"
          label="Código Correspondente à Parte Atingida"
          name="strCodigoParteAtingida"
          item-text="strNome"
          item-value="strCodigo"
          route="Esocial/EsocialTabelaConteudo"
          :filters="{
            strBusca: this.formData.strCodigoParteAtingida,
            intEsocialTabelaId: 13,
          }"
          :initializeItems="formData.strCodigoParteAtingida ? true : false"
          rules="required"
        />

        <ed-input-select
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.intTipoParteAtingidaId"
          name="intTipoParteAtingidaId"
          label="Lateralidade da parte atingida"
          :items="bind.arrayTipoParteAtengidaCat"
          rules="required"
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-612 col-lg-12 col-xl-12 mt-0 pt-0"
          v-model="formData.strCodigoAgenteCausador"
          label="Agente causador do acidente"
          name="strCodigoAgenteCausador"
          item-text="strNome"
          item-value="strCodigo"
          route="Esocial/EsocialTabelaConteudo"
          :filters="{
            strBusca: this.formData.strCodigoAgenteCausador,
            intEsocialTabelaId: 14,
          }"
          :initializeItems="formData.strCodigoAgenteCausador ? true : false"
          rules="required"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputAutoComplete,
  EdInputDate,
  EdInputTime,
} from "@/components/common/form";
import EdEndereco from "@/components/cadastro/geral/partials/endereco";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputAutoComplete,
    EdInputDate,
    EdInputTime,
    EdEndereco,
  },
  inject: ["bind"],
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    boolExibirCat() {
      return (
        this.formData.intCadastroGeralEmpresaId &&
        this.formData.intFuncionarioId &&
        this.formData.intTipoAbsenteismoId == 1348
      );
    },
    boolDisableForm() {
      return (
        this.formData.intCadastroGeralEmpresaId &&
        this.formData.intFuncionarioId &&
        this.formData.intTipoAbsenteismoId
      );
    },
  },
  methods: {
    setFuncionario(obj) {
      if (obj) {
        this.formData.funcionario = obj;
      }
    },

    getEmpresaFuncionario() {
      let arrayEmpresa = [];
      if (this.formData.funcionario) {
        this.formData.funcionario.empresas.forEach((element) => {
          arrayEmpresa.push(element.empresa);
        });
      }

      return arrayEmpresa;
    },
  },
};
</script>
