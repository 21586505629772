<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral
        :formData="formData"
        v-show="strTab == 'tab-cadastro'"
        :key="'cadastro-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import { EdForm } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intAbsenteismoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        intTipoAbsenteismoId: null,
        intCadastroGeralEmpresaId: null,
        intCadastroGeralUnidadeId: null,
        intCadastroGeralSetorId: null,
        intFuncionarioId: null,
        intProfissionald: null,
        intFuncaoId: null,
        endereco: {
          intId: 0,
          strCidade: null,
          intCep: null,
          strEstado: null,
          strLogradouro: null,
          strNumero: null,
          strComplemento: null,
          strBairro: null,
        },
      },
      bind: {
        arrayTipoAbsenteismo: [],
        arrayTipoCat: [],
        arrayTipoIniciativaCat: [],
        arrayTipoAcidenteCat: [],
        arrayTipoLocalAcidenteCat: [],
        arrayTipoParteAtengidaCat: [],
        arrayTipoInscricaoLocalAcidenteCat: [],
        arrayTipoIniciativaCat: [],
        arrayBoolean: [
          { intId: 1, strNome: "SIM" },
          { intId: 0, strNome: "NÃO" },
        ],
      },
      boolCarregado: false,
    };
  },
  watch: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Absenteismo/Absenteismo", {
          intAbsenteismoId: this.intAbsenteismoId,
          boolInicializar: true,
        })
        .then((objResponse) => {
        
          if (objResponse.status == 200) {
            if (objResponse.result.objAbsenteismo) {
              if (!objResponse.result.objAbsenteismo.endereco) {
                objResponse.result.objAbsenteismo.endereco = {
                  intId: 0,
                  strCidade: null,
                  intCep: null,
                  strEstado: null,
                  strLogradouro: null,
                  strNumero: null,
                  strComplemento: null,
                  strBairro: null,
                };
              }

              this.formData = Object.assign(
                objResponse.result.objAbsenteismo,
                {}
              );
            }

            this.bind.arrayTipoAbsenteismo = objResponse.result.arrayTipo[1346];
            this.bind.arrayTipoCat = objResponse.result.arrayTipo[1358];
            this.bind.arrayTipoIniciativaCat =
              objResponse.result.arrayTipo[1362];
            this.bind.arrayTipoLocalAcidenteCat =
              objResponse.result.arrayTipo[1366];
            this.bind.arrayTipoParteAtengidaCat =
              objResponse.result.arrayTipo[1374];
            this.bind.arrayTipoAcidenteCat = objResponse.result.arrayTipo[1379];
            this.bind.arrayTipoInscricaoLocalAcidenteCat =
              objResponse.result.arrayTipo[70];
            this.bind.arrayTipoIniciativaCat =
              objResponse.result.arrayTipo[1362];
          }
          this.boolCarregado = true;
          this.$root.$dialog.loading(false);
        });
    },

    salvar() {
      this.$root.$request
        .post("Absenteismo/Absenteismo", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name != "absenteismo.edit") {
              this.$router.push({
                name: "absenteismo.edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.initialize();
            }
          }
        });
    },
  },
};
</script>
